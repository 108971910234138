<template>
  <main class="layout-main">
    <slot/>
  </main>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.layout-main {
  min-height: calc(100vh - #{$common-layout__horizontal-menu-height + 42px});
}
</style>
