
import { defineComponent } from 'vue'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import Toolbar from 'primevue/toolbar'

export default defineComponent({
  name: 'HorizontalMenu',
  components: {
    Toolbar
  },

  emits: [
    'showSideMenu'
  ],
  props: {
    brand: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const session = useUserSessionStore()
    const showSideMenu = () => {
      emit('showSideMenu')
    }
    return {
      showSideMenu,
      session
    }
  }
})
